import * as RadioGroup from "@radix-ui/react-radio-group";
import { ArrowUpCircle } from "lucide-react";
import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { ButtonIds, StudioEventTypes } from "sutro-analytics";
import { DEVICE_PLATFORMS, PublishMeta } from "sutro-common";
import { PostHogFlags } from "sutro-common/feature-flags/posthog-flags";
import { hasEntitlement } from "sutro-common/studio-entitlements";
import { z } from "zod";

import { Button } from "~/components/ui/button";
import {
  DialogDescription,
  DialogFooter,
  DialogTitle,
} from "~/components/ui/dialog";
import { PremiumWrapper } from "~/components/ui/premium-wrapper";
import { useFeatureFlags } from "~/lib/hooks/use-feature-flag";
import { useStudio } from "~/lib/hooks/use-studio";
import { useAnalytics } from "~/lib/use-analytics";
import { appPublishSchema } from "~/lib/use-publish";
import { cn } from "~/lib/utils";
import { useEntitlements } from "~/providers/EntitlementsProvider/entitlements-provider";

import { PlatformAndroidIcon } from "./platform-android-icon";
import { PlatformIosIcon } from "./platform-ios-icon";
import { PlatformRadioItem } from "./platform-radio-item";
import { PlatformWebIcon } from "./platform-web-icon";

const step1Schema = appPublishSchema.pick({
  platform: true,
});

function UpgradeButton({
  onRequestUpgrade,
  message,
}: {
  onRequestUpgrade: () => void;
  message: string;
}) {
  return (
    <Button
      className="flex flex-row justify-between gap-2 border border-warning-strong bg-warning-strong px-4 py-2 text-black hover:bg-warning-strong/80"
      onClick={onRequestUpgrade}
    >
      <ArrowUpCircle size={20} />
      <div className="w-min">{message}</div>
    </Button>
  );
}

export function Step1({
  onContinue,
  onCancel,
  onRequestUpgrade,
  publishMeta,
}: {
  onContinue: () => void;
  onCancel: () => void;
  onRequestUpgrade: () => void;
  publishMeta: PublishMeta | null;
}) {
  const { entitlements, currentSubscription } = useEntitlements();
  const flags = useFeatureFlags(PostHogFlags.PremiumFeatures);
  const canPublishOnNativeApps =
    !flags[PostHogFlags.PremiumFeatures] ||
    hasEntitlement(entitlements, "publish-to-app-store") ||
    hasEntitlement(entitlements, "publish-to-google-play");

  const canPublishOnWeb =
    !flags[PostHogFlags.PremiumFeatures] ||
    hasEntitlement(entitlements, "publish-to-mobile-web");

  const isOnFreeTier = currentSubscription.tier.name === "Free";

  const { setValue, watch } = useFormContext<z.infer<typeof step1Schema>>();
  const platform = watch("platform");
  const { track } = useAnalytics();

  const { product } = useStudio();

  const isPublishedOnWeb = useMemo(() => {
    return Boolean(product?.productionVersionId);
  }, [product]);

  const handleNextStep = useCallback(() => {
    track(StudioEventTypes.BUTTON_CLICK, {
      buttonId: ButtonIds.PUBLISH_FLOW_CHOOSE_PLATFORM,
      platform,
    });
    onContinue();
  }, [platform, onContinue, track]);

  const showUpgradeButton = !canPublishOnNativeApps;

  const isRepublishing = publishMeta?.publishedProductIds.includes(
    product?.id ?? ""
  );

  /**
   * @returns {boolean} True if web publishing is allowed, false otherwise
   * - Returns true if user has web publishing entitlement and either:
   *   - No max publish limit exists
   *   - Max publish limit hasn't been reached
   *   - Or this is a republish of an existing app
   * - Returns false if:
   *   - User doesn't have web publishing entitlement
   *   - Max publish limit reached and this isn't a republish
   */
  const shouldAllowWebPublish = useMemo(() => {
    if (canPublishOnWeb && publishMeta) {
      if (publishMeta.maxPublishedAppsCount === null) {
        return true;
      }

      const { currentlyPublishedAppsCount, maxPublishedAppsCount } =
        publishMeta;
      const reachedMaxPublishedAppsCount =
        currentlyPublishedAppsCount >= maxPublishedAppsCount;

      if (reachedMaxPublishedAppsCount && !isRepublishing) {
        return false;
      } else {
        return true;
      }
    }

    return false;
  }, [canPublishOnWeb, isRepublishing, publishMeta]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <DialogTitle>Publish app</DialogTitle>
        <DialogDescription>
          {publishMeta?.currentlyPublishedAppsCount === 0 ? (
            <>
              Which platform do you want to publish your app on? To publish your
              app on more than one platform, simply complete the process for one
              platform and click “Publish” in the studio to begin the process
              again for another platform.
            </>
          ) : (
            <>
              {publishMeta?.currentlyPublishedAppsCount}/
              {publishMeta?.maxPublishedAppsCount === null
                ? "?"
                : publishMeta?.maxPublishedAppsCount}{" "}
              apps published in your workspace based on your{" "}
              {currentSubscription.tier.name} plan.
            </>
          )}
        </DialogDescription>
      </div>

      <RadioGroup.Root
        value={platform}
        onValueChange={(platform) => {
          setValue("platform", platform as DEVICE_PLATFORMS);
        }}
        className="flex flex-col gap-2"
      >
        <PlatformRadioItem
          platform={DEVICE_PLATFORMS.MOBILE_WEB}
          icon={<PlatformWebIcon />}
          title="Web"
          description={
            isOnFreeTier ? "Apps display a Sutro badge on the Free plan" : ""
          }
          published={isPublishedOnWeb}
          disabled={!shouldAllowWebPublish}
        />
        <PremiumWrapper tier="Pro" entitlementKey="publish-to-app-store">
          <PlatformRadioItem
            platform={DEVICE_PLATFORMS.IOS}
            icon={<PlatformIosIcon />}
            title="iOS"
            description=""
            disabled={
              !publishMeta?.allowedPlatforms.includes(DEVICE_PLATFORMS.IOS)
            }
          />
        </PremiumWrapper>
        <PremiumWrapper tier="Pro" entitlementKey="publish-to-google-play">
          <PlatformRadioItem
            platform={DEVICE_PLATFORMS.ANDROID}
            icon={<PlatformAndroidIcon />}
            title="Android"
            description=""
            disabled={
              !publishMeta?.allowedPlatforms.includes(DEVICE_PLATFORMS.ANDROID)
            }
          />
        </PremiumWrapper>
      </RadioGroup.Root>
      <DialogFooter className="w-full">
        <div
          className={cn({
            "flex w-full flex-row justify-between": showUpgradeButton,
          })}
        >
          {showUpgradeButton && (
            <div className="flex grow flex-row justify-start">
              <UpgradeButton
                onRequestUpgrade={onRequestUpgrade}
                message="Upgrade to Pro"
              />
            </div>
          )}
          <div className="flex flex-row gap-2">
            <Button
              variant="secondary"
              onClick={onCancel}
              testId={ButtonIds.PUBLISH_FLOW_CANCEL}
            >
              Cancel
            </Button>
            <Button
              disabled={!platform}
              onClick={handleNextStep}
              type="button"
              testId={ButtonIds.PUBLISH_FLOW_NEXT_STEP}
            >
              Continue
            </Button>
          </div>
        </div>
      </DialogFooter>
    </div>
  );
}
